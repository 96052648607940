import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import Breadcrumb from "components/Breadcrumb";
import Button from "components/Button";
import DeletedAlert from "components/DeletedAlert";
import { Board as BaseBoard, TitleArea, FormRow, AlertArea, ContentArea, FooterArea } from "components/LayoutParts";
import RadioButton from "components/RadioButton";
import Select from "components/Select";
import TextButton from "components/TextButton";
import TextForm from "components/TextForm";
import UploadForm from "components/UploadForm";
import UserModal, { UserModalConfig } from "components/UserModal";
import color from "constants/color";
import font from "constants/font";
import { HOURS, MINUTES } from "constants/time";
import { useSwr } from "hooks/useSwr";
import { initPriceStructure, initSpace, useRemoveProperty, useUpdateProperty } from "pages/PitPort/Property/api";
import PriceStructure from "pages/PitPort/Property/PriceStructure";
import SpaceForm from "pages/PitPort/Property/SpaceForm";
import { timeFormat, timeToDayjs } from "utils/dateTimeUtils";

// パーツ定義
const Board = styled(BaseBoard)`
  margin-bottom: 88px;
`;
const DeleteWrap = styled.div`
  display: flex;
  flex: auto;
  justify-content: flex-end;
`;
const Label = styled.div`
  min-width: 120px;
  margin: 0 5px;
`;
const CancelWrap = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
`;
const SaveWrap = styled(CancelWrap)`
  align-items: center;
  justify-content: flex-end;
`;
const Annotation = styled.span`
  font-size: ${font.size12};
  color: ${color.text};
  margin-right: 16px;
`;
const ErrorArea = styled.div<{
  isErrorDisplay: boolean;
}>`
  display: ${(props) => (props.isErrorDisplay ? "block" : "none")};
  position: absolute;
  white-space: nowrap;
  color: ${color.attention};
  margin: 2px 0;
`;
const ErrorMessage = styled.span`
  font-size: ${font.size12};
`;

const initialProperty: Property = {
  id: "0",
  name: "",
  address: "",
  lat: "",
  lng: "",
  availableStartDatetime: "09:00",
  availableEndDatetime: "19:00",
  usageFeePerFifteenMinutes: 1,
  status: 1, // ０:利用不可、1：利用可
  images: [],
  spaces: [initSpace],
  ownerEntityId: 1, // landit
  priceStructureState: initPriceStructure,
};

const EditProperty = () => {
  const navigate = useNavigate();
  const { update } = useUpdateProperty();
  const { remove } = useRemoveProperty();
  const params = useParams();
  const { data: originProperty, error } = useSwr<PropertyResponse>(`/property/${params.id}`);
  const property = originProperty ?? initialProperty;

  const startTimeHour = property.availableStartDatetime.split(":")[0];
  const startTimeMinute = property.availableStartDatetime.split(":")[1];
  const endTimeHour = property.availableEndDatetime.split(":")[0];
  const endTimeMinute = property.availableEndDatetime.split(":")[1];

  const [name, setName] = useState(property.name);
  const [address, setAddress] = useState(property.address);
  const [latlng, setLatlng] = useState(`${property.lat}, ${property.lng}`);
  const [availableStartHour, setAvailableStartHour] = useState(startTimeHour.replace(/^0+/, ""));
  const [availableStartMinute, setAvailableStartMinute] = useState(startTimeMinute);
  const [availableEndHour, setAvailableEndHour] = useState(endTimeHour.replace(/^0+/, ""));
  const [availableEndMinute, setAvailableEndMinute] = useState(endTimeMinute);
  const [usageFeePerFifteenMinutes, setUsageFeePerFifteenMinutes] = useState(property.usageFeePerFifteenMinutes);
  const [status, setStatus] = useState(property.status); // 1:true、0:false
  const [images, setImages] = useState<Image[]>(property.images);
  const [unUploadedImages, setUnUploadedImages] = useState<File[]>([]);
  const [spaces, setSpaces] = useState<Space[]>(property.spaces);
  const [priceStructure, setPriceStructure] = useState<PriceStructureState>(
    property.priceStructureState || initPriceStructure
  );

  const [isModalShow, setIsModalShow] = useState(false);
  const [ModalConfig, setModalConfig] = useState<UserModalConfig>({
    onClickOutside: () => {
      return;
    },
    subtitle: "完了しました",
    main: {
      buttonType: "secondary",
      onClick: () => {
        return;
      },
      label: "閉じる",
    },
  });

  const showModal = (config: UserModalConfig) => {
    setModalConfig(config);
    setIsModalShow(true);
  };

  const hideModal = () => {
    setIsModalShow(false);
  };

  const setUp = useCallback(() => {
    if (error) {
      showModal({
        onClickOutside: hideModal,
        subtitle: "送信に失敗しました",
        main: {
          buttonType: "secondary",
          onClick: hideModal,
          label: "閉じる",
        },
        errorMessage: "時間をおいてもう一度お試しください",
      });
      return;
    }
    setName(property.name);
    setAddress(property.address);
    setLatlng(`${property.lat}, ${property.lng}`);
    setAvailableStartHour(startTimeHour.replace(/^0/, ""));
    setAvailableStartMinute(startTimeMinute);
    setAvailableEndHour(endTimeHour.replace(/^0/, ""));
    setAvailableEndMinute(endTimeMinute);
    setStatus(property.status);
    setImages(property.images);
    setUsageFeePerFifteenMinutes(property.usageFeePerFifteenMinutes);
    setSpaces(property.spaces);
    setPriceStructure(property.priceStructureState || initPriceStructure);
  }, [error, property, startTimeHour, startTimeMinute, endTimeHour, endTimeMinute]);

  useEffect(() => {
    if (!property) return;
    setUp();
  }, [property, setUp]);

  const changeName = (e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value);
  const changeAddress = (e: React.ChangeEvent<HTMLInputElement>) => setAddress(e.target.value);
  const changeLatlng = (e: React.ChangeEvent<HTMLInputElement>) => setLatlng(e.target.value);
  const changeUsageFeePerFifteenMinutes = (e: React.ChangeEvent<HTMLInputElement>) =>
    setUsageFeePerFifteenMinutes(Number(e.target.value));

  const isErrorName = !(name.length > 0);
  const isErrorAddress = !(address.length > 0);
  const isErrorLatlng = !/^[0-9.]+, [0-9.]+$/.test(latlng);
  const availableStartTimeToDayjs = timeToDayjs(availableStartHour, availableStartMinute);
  const availableEndTimeToDayjs = timeToDayjs(availableEndHour, availableEndMinute);
  const isErrorAfterStartTime = availableStartTimeToDayjs.isAfter(availableEndTimeToDayjs);
  const isErrorUsageFee = !/^([1-9][0-9]*)$/.test(`${usageFeePerFifteenMinutes}`);
  const isErrorSpaceName = spaces.filter((space) => space.name.length === 0).length > 0;
  const isErrorSpaces = !(spaces.length > 0);
  const isErrorSpaceWidth = spaces.filter((space) => space.width === 0).length > 0;
  const isErrorSpaceLength = spaces.filter((space) => space.length === 0).length > 0;
  const isErrorContIdLength = spaces.filter((space) => space.contId && space.contId?.length !== 12).length > 0;
  const filteredSpacesByStandId = spaces.filter((space) => space.standId);
  const filteredSpacesByContId = spaces.filter((space) => space.contId);
  const isErrorDuplicateStandIds =
    new Set(filteredSpacesByStandId.map((space) => space.standId)).size !== filteredSpacesByStandId.length;
  const isErrorDuplicateContIds =
    new Set(filteredSpacesByContId.map((space) => space.contId)).size !== filteredSpacesByContId.length;

  const canSubmit =
    !isErrorName &&
    !isErrorAddress &&
    !isErrorLatlng &&
    !isErrorAfterStartTime &&
    !isErrorUsageFee &&
    !isErrorSpaceName &&
    !isErrorSpaces &&
    !isErrorDuplicateStandIds &&
    !isErrorDuplicateContIds &&
    !isErrorSpaceLength &&
    !isErrorSpaceWidth &&
    !isErrorContIdLength;

  const onClickSubmit = useCallback(async () => {
    const { status: responseStatus, message } = await update(unUploadedImages, {
      id: Number(property.id),
      name,
      address,
      lat: latlng.split(", ")[0],
      lng: latlng.split(", ")[1],
      usageFeePerFifteenMinutes,
      availableStartDatetime: timeFormat(availableStartHour, availableStartMinute),
      availableEndDatetime: timeFormat(availableEndHour, availableEndMinute),
      status: status === 0 ? 0 : 1,
      images,
      spaces,
      priceStructureState: priceStructure,
      ownerEntityId: property.ownerEntityId ?? 1, // landit
    });
    responseStatus === 200
      ? showModal({
          onClickOutside: hideModal,
          subtitle: "変更内容を保存しました",
          main: {
            buttonType: "secondary",
            onClick: () => navigate("/pit_port/property"),
            label: "閉じる",
          },
        })
      : showModal({
          onClickOutside: hideModal,
          subtitle: "送信に失敗しました",
          main: {
            buttonType: "secondary",
            onClick: hideModal,
            label: "閉じる",
          },
          errorMessage: responseStatus === 400 ? message : "時間をおいてもう一度お試しください",
        });
  }, [
    property.id,
    name,
    address,
    latlng,
    availableEndHour,
    availableEndMinute,
    availableStartHour,
    availableStartMinute,
    usageFeePerFifteenMinutes,
    status,
    images,
    unUploadedImages,
    spaces,
    update,
    navigate,
    property.ownerEntityId,
    priceStructure,
  ]);

  const onClickRemove = useCallback(async () => {
    const result = await remove(Number(property.id));
    result === 200
      ? showModal({
          onClickOutside: hideModal,
          subtitle: "削除しました",
          main: {
            buttonType: "secondary",
            onClick: () => navigate("/pit_port/property"),
            label: "閉じる",
          },
        })
      : showModal({
          onClickOutside: hideModal,
          subtitle: "送信に失敗しました",
          main: {
            buttonType: "secondary",
            onClick: hideModal,
            label: "閉じる",
          },
          errorMessage: "時間をおいてもう一度お試しください",
        });
  }, [remove, property.id, navigate]);

  const showRemoveModal = () => {
    setModalConfig({
      onClickOutside: hideModal,
      subtitle: "削除しますか?",
      main: {
        buttonType: "primary",
        onClick: () => {
          onClickRemove();
        },
        label: "削除する",
      },
      sub: {
        buttonType: "secondary",
        onClick: hideModal,
        label: "閉じる",
      },
    });
    setIsModalShow(true);
  };

  return (
    <Board>
      <TitleArea>
        <Breadcrumb
          currentPageName="駐車場を編集"
          breadcrumbItems={[{ pageName: "駐車場", onClick: () => navigate("/pit_port/property") }]}
        />
        {status !== 5 && (
          <DeleteWrap>
            <Button label="この駐車場を削除" onClick={showRemoveModal} type="danger" />
          </DeleteWrap>
        )}
      </TitleArea>
      {status === 5 && <DeletedAlert message="この駐車場は削除済みです" />}
      <ContentArea>
        <FormRow>
          <Label>駐車場名</Label>
          <TextForm
            type="text"
            width="350px"
            value={name}
            placeholder="例）ザイマックス三田ビル"
            required
            onChange={changeName}
            isError={isErrorName}
            errorMessage="物件名を入力してください"
          />
        </FormRow>
        <FormRow>
          <Label>住所</Label>
          <TextForm
            type="text"
            width="540px"
            value={address}
            placeholder="例）東京都港区芝5-13-11"
            required
            onChange={changeAddress}
            isError={isErrorAddress}
            errorMessage="住所を入力してください"
          />
          <div style={{ marginLeft: "16px" }}>
            <TextButton
              label="Googleマップで確認"
              onClick={() => {
                window.open(`http://maps.google.co.jp/maps?q=${address}`, "_blank");
                return;
              }}
            />
          </div>
        </FormRow>
        <FormRow>
          <Label>緯度経度</Label>
          <TextForm
            type="text"
            width="540px"
            value={latlng}
            placeholder="例）35.649120216601474, 139.745112924581"
            required
            onChange={changeLatlng}
            isError={isErrorLatlng}
            errorMessage="緯度, 経度 の形式で入力してください"
          />
        </FormRow>
        <FormRow style={{ position: "relative" }}>
          <Label>予約可能時間</Label>
          <div style={{ marginRight: "8px" }}>開始</div>
          <Select choices={HOURS} value={availableStartHour} width="100px" required onSelect={setAvailableStartHour} />
          <div style={{ marginLeft: "8px", marginRight: "8px" }}>時</div>
          <Select
            choices={MINUTES}
            value={availableStartMinute}
            width="100px"
            required
            onSelect={setAvailableStartMinute}
          />
          <div style={{ marginLeft: "8px" }}>分</div>
          <div style={{ marginLeft: "32px", marginRight: "8px" }}>終了</div>
          <Select choices={HOURS} value={availableEndHour} width="100px" required onSelect={setAvailableEndHour} />
          <div style={{ marginLeft: "8px", marginRight: "8px" }}>時</div>
          <Select
            choices={MINUTES}
            value={availableEndMinute}
            width="100px"
            required
            onSelect={setAvailableEndMinute}
          />
          <div style={{ marginLeft: "8px" }}>分</div>
          <ErrorArea
            isErrorDisplay={isErrorAfterStartTime}
            style={{ position: "absolute", bottom: "0", left: "31rem" }}
          >
            <ErrorMessage>開始時間より後の時間を選択してください</ErrorMessage>
          </ErrorArea>
        </FormRow>
        <FormRow>
          <Label>料金（15分）</Label>
          <TextForm
            type="number"
            width="100px"
            value={usageFeePerFifteenMinutes}
            placeholder="例）50"
            required
            onChange={changeUsageFeePerFifteenMinutes}
            isError={isErrorUsageFee}
            errorMessage="1円以上を入力してください"
          />
          <div style={{ marginLeft: "8px" }}>円</div>
        </FormRow>
        <FormRow>
          <Label>画像</Label>
          <div style={{ width: "520px" }}>
            <UploadForm
              showImages={images}
              setShowImages={setImages}
              unUploadedImages={unUploadedImages}
              setUnUploadedImages={setUnUploadedImages}
            />
          </div>
        </FormRow>
        {status !== 5 && (
          <FormRow>
            <Label>状態</Label>
            <RadioButton
              id="status"
              value={status}
              choices={[
                {
                  label: "利用可",
                  value: 1,
                },
                {
                  label: "利用不可",
                  value: 0,
                },
              ]}
              onChange={(e) => setStatus(Number(e.target.value) === 0 ? 0 : 1)}
            />
          </FormRow>
        )}
      </ContentArea>
      <PriceStructure priceStructure={priceStructure} setPriceStructure={setPriceStructure} />
      {spaces.length === 0 && (
        <AlertArea>
          <FontAwesomeIcon icon={faCircleExclamation} />
          <span style={{ color: color.text.black, marginLeft: "8px" }}>区画を追加してください</span>
        </AlertArea>
      )}
      {spaces.map((space, index) => (
        <SpaceForm key={`space_${index}`} index={index} space={space} setSpaces={setSpaces} spaces={spaces} />
      ))}
      <TitleArea>
        <Button label="区画を追加する" onClick={() => setSpaces((prev) => [...prev, initSpace])} type="secondary" />
      </TitleArea>
      <FooterArea>
        <CancelWrap>
          <Button type="secondary" onClick={() => navigate("/pit_port/property")} label="キャンセル" width="160px" />
        </CancelWrap>
        {status !== 5 && (
          <SaveWrap>
            <Annotation>追加するまで変更内容は反映されません</Annotation>
            <Button type="primary" onClick={onClickSubmit} label="編集内容を保存" disabled={!canSubmit} width="160px" />
          </SaveWrap>
        )}
      </FooterArea>
      <UserModal isVisible={isModalShow} config={ModalConfig}></UserModal>
    </Board>
  );
};

export default EditProperty;
