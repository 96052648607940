import { Title, TitleArea, ContentArea } from "components/LayoutParts";
import DayPriceSetting from "pages/PitPort/Property/components/DayPriceSetting";

const PriceStructure = ({
  priceStructure,
  setPriceStructure,
}: {
  priceStructure: PriceStructureState;
  setPriceStructure: React.Dispatch<React.SetStateAction<PriceStructureState>>;
}) => {
  const changeField = (day: keyof PriceStructureState, field: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    let value: string | number = e.target.value;
    const numberFields = ["price", "maxPrice", "max_price", "maxDurationHours", "maxPricePerDuration"];

    if (numberFields.some((f) => field.includes(f))) {
      value = Number(e.target.value);
    }

    setPriceStructure((prev) => {
      // state の更新対象が timeSlot1 または timeSlot2 の場合、
      // field 名から対象を判定して更新する
      if (field.startsWith("timeSlot1_")) {
        const key = field.replace("timeSlot1_", "") as keyof TimeSlotData;
        return {
          ...prev,
          [day]: {
            ...prev[day],
            timeSlot1: {
              ...prev[day].timeSlot1,
              [key]: value,
            },
          },
        };
      } else if (field.startsWith("timeSlot2_")) {
        const key = field.replace("timeSlot2_", "") as keyof TimeSlotData;
        return {
          ...prev,
          [day]: {
            ...prev[day],
            timeSlot2: {
              ...prev[day].timeSlot2,
              [key]: value,
            },
          },
        };
      } else {
        // maxDurationHours や maxPricePerDuration の場合
        return {
          ...prev,
          [day]: {
            ...prev[day],
            [field]: value,
          },
        };
      }
    });
  };

  return (
    <>
      <TitleArea>
        <Title>料金設定</Title>
      </TitleArea>
      <ContentArea>
        <DayPriceSetting
          title="月曜日〜金曜日"
          priceStructure={priceStructure.weekday as PriceStructureData}
          // priceStructure={{}}
          onChange={(field, e) => changeField("weekday", field)(e)}
        />
        <div style={{ marginBottom: "20px" }} />
        <DayPriceSetting
          title="土・日曜日"
          priceStructure={priceStructure.dayOff as PriceStructureData}
          onChange={(field, e) => changeField("dayOff", field)(e)}
        />
        <div style={{ marginBottom: "20px" }} />
        <DayPriceSetting
          title="祝日"
          priceStructure={priceStructure.holiday as PriceStructureData}
          onChange={(field, e) => changeField("holiday", field)(e)}
        />
      </ContentArea>
    </>
  );
};

export default PriceStructure;
