import styled from "styled-components";

import TextForm from "components/TextForm";
import color from "constants/color";

const BorderContainer = styled.div`
  display: flex;
  border-right: 1px solid ${color.border};
`;

const Label = styled.p`
  margin-bottom: 10px;
  font-size: 14px;
`;

const FlexCenter = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
`;

const MarginRight = styled.div`
  margin-right: 10px;
`;

const MarginRightFive = styled.div`
  margin-right: 5px;
`;

const SpanWithMargin = styled.span`
  margin-left: 5px;
  margin-right: 5px;
`;

interface Props {
  onChange: (field: string, e: React.ChangeEvent<HTMLInputElement>) => void;
  priceStructure: PriceStructureData;
  fieldPrefix: "timeSlot1" | "timeSlot2";
}

const TimePriceBlock = ({ onChange, priceStructure, fieldPrefix }: Props) => {
  const timeSlot: TimeSlotData = priceStructure[fieldPrefix];
  return (
    <BorderContainer>
      <div>
        <Label>開始時間</Label>
        <FlexCenter>
          <TextForm
            type="text"
            width="50px"
            value={timeSlot.startHour}
            placeholder=""
            required
            onChange={(e) => onChange(`${fieldPrefix}_startHour`, e)}
            isError={false}
            errorMessage=""
          />
          <SpanWithMargin>:</SpanWithMargin>
          <TextForm
            type="text"
            width="50px"
            value={timeSlot.startMinute}
            placeholder=""
            required
            onChange={(e) => onChange(`${fieldPrefix}_startMinute`, e)}
            isError={false}
            errorMessage=""
          />
        </FlexCenter>
      </div>
      <MarginRight>
        <Label>時間料金（15分）</Label>
        <FlexCenter>
          <TextForm
            type="number"
            width="80px"
            value={timeSlot.price}
            placeholder=""
            required
            onChange={(e) => onChange(`${fieldPrefix}_price`, e)}
            isError={false}
            errorMessage=""
          />
          <MarginRightFive />
          <span>円</span>
        </FlexCenter>
      </MarginRight>
      <MarginRight>
        <Label>時間帯最大料金</Label>
        <FlexCenter>
          <TextForm
            type="number"
            width="90px"
            value={timeSlot.maxPrice}
            placeholder=""
            required
            onChange={(e) => onChange(`${fieldPrefix}_maxPrice`, e)}
            isError={false}
            errorMessage=""
          />
          <MarginRightFive />
          <span>円</span>
        </FlexCenter>
      </MarginRight>
    </BorderContainer>
  );
};

export default TimePriceBlock;
